import React, { useContext } from "react"
import "./TeamMember.scss"
import Story from "../../components/Stories/Stories"
import { ReactComponent as TeamMemberIcon } from "../../assets/icons/linked-in-icon-black.svg"
import { LanguageContext } from "../../contexts/languageContext"
import { getLocalizedField } from "../../utils/getLocalizedField"


const DURATION_OF_ONE_STORY = 4000

/**
 * Testimonial component.
 *
 * @param {Object} user - eg. {name: ..., position: {name: ...}, image: ...}
 * @param {Array of Objects} user.image - eg. [{formats: {team: {width: 100, height: 100, url: "/uploads/team_someimagename.jpg"}}, hp: {width: 200, height: 200, url: "/uploads/hp_someimagename.jpg"}}}, {...}]
 * @return {string} TeamMember component.
 */

const TeamMember = ({ profile, storyIndex }) => {
  const { id, user, linkedin, position, image } = profile

  const languageContext = useContext(LanguageContext)
  const currentLanguage = languageContext[0]

  const localizedPositionName =
    position && position[getLocalizedField("name", currentLanguage.label)]
  const localizedUsername =
    user && user[getLocalizedField("username", currentLanguage.label)]

  return (
    <div className="team-member">
      <Story transparent index={storyIndex} duration={DURATION_OF_ONE_STORY}>
        {image.length > 0 &&
          image.map(img => {
            const {
              formats: { team },
              alternativeText,
            } = img
            const { src } = img.staticImages.team[img.staticImages.team.length - 1]
            const { width, height, hash } = team[0]
            return (
              <div key={`${hash}-${id}`}>
                <img
                  src={`${src}`}
                  className="story-image"
                  alt={alternativeText}
                  width={`${width}px`}
                  height={`${height}px`}
                />
              </div>
            )
          })}
      </Story>
      <div className="team-member-about">
        <div>
          <h3 className="about-title">{localizedUsername}</h3>
          <span className="about-subtitle">{localizedPositionName}</span>
        </div>

        <a href={linkedin} target="_blank" rel="noreferrer">
          <TeamMemberIcon />
        </a>
      </div>
    </div>
  )
}

export default TeamMember
