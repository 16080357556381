import React from "react"
import "./Double.scss"
import num1Icon from "../../assets/images/num1-icon.svg"
import num2Icon from "../../assets/images/num2-icon.svg"


/*
@param image - String, eg. "static/images/example-image.png"
@param content - Array of objects, eg. [{heading1: "Example heading 1", body1: "Example body 1", heading2: "Example heading 2", body2: "Example body 2"}]
*/
const Double = ({ image, content }) => {
  const { title1, title2, text1, text2 } = content

  return (
    <div className="double-container">
      {image && (
        <picture className="main-image">
          <source
            media="(max-width: 767px)"
            srcSet={`${image[0].staticImages.double_sm[0].src}`}
          />
          <img
            src={`${image[0].staticImages.double[0].src}`}
            alt={image[0].alternativeText}
            width={image[0].formats.double[0].width}
            height={image[0].formats.double[0].height}
          />
        </picture>
      )}

      <div className="content-container">
        <div className="content">
          {num1Icon && (
            <img alt="Number one icon" src={num1Icon} width="61" height="61" />
          )}

          <div className="inner-text">
            <h2>{title1}</h2>
            <p>{text1}</p>
          </div>
        </div>
        <div className="content">
          {num2Icon && (
            <img alt="Number two icon" src={num2Icon} width="61" height="61" />
          )}

          <div className="inner-text">
            <h2>{title2}</h2>
            <p>{text2}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Double
