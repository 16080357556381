import React, { useMemo } from "react"
import { Layout } from "../../layouts"
import TopComponent from "../../components/TopComponent/TopComponent"
import ZigZag from "../../components/ZigZag/ZigZag"
import "./Company.scss"
import Double from "../../components/Double/Double"
import Contact from "../../components/Contact/Contact"
import Testimonial from "../../components/Testimonial/Testimonial"
import gifImage from "../../assets/images/gif-images/gif-big.svg"
import TestimonialSlider from "../../components/TestimonialSlider/TestimonialSlider"
import TeamMember from "../../components/TeamMember/TeamMember"
import Wave from "../../components/Wave/Wave"
import WeUse from "../../components/WeUse/WeUse"
import { graphql } from "gatsby"
import { MESSAGE_TYPES } from "../../constants"
import SEO from "../../components/Seo"
import { useZigzagImageSides } from "../../hooks/useZigzagImageSides"
import { localizeDataNodes, localizeStaticNodes } from "../../utils/localizeDataNodes"
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter"

const ZIGZAG_IMAGE_SIDE_START = "right"

const Company = ({ pageContext: { pageData, lang, testimonials, doubles, profiles, companies }, data, location }) => {
  const {
    allDzemoApiArticles,
    allDzemoApiZigzags,
  } = useMemo(() => localizeDataNodes(data, lang), [data, lang])

  const zigzagImageSides = useZigzagImageSides(
    allDzemoApiZigzags,
    ZIGZAG_IMAGE_SIDE_START
  )

  const localizedTops = useMemo(() => localizeStaticNodes(pageData[lang].tops, lang), [pageData, lang])
  const localizedZigzags = useMemo(() => localizeStaticNodes(pageData[lang].zigzags, lang), [pageData, lang])

  return (
    <Layout location={location} lang={lang} pageData={pageData} companies={companies}>
      <SEO
        title={capitalizeFirstLetter(pageData[lang].name)}
        canonical={location.pathname}
        data={pageData[lang]}
      />
      <div className="company-page">
        <section
          style={{
            display: "flex",
            minHeight: "250px",
          }}
        >
          {localizedTops.length > 0 && (
            <TopComponent
              data={localizedTops}
              imageFormatName={"normal"}
              wave
            />
          )}
        </section>

        {doubles.map(double => {
          const { id, image } = double.node[lang]
          return <Double key={id} image={image} content={double.node[lang]} />
        })}

        <div className="gif-content-middle">
          <img src={gifImage} alt="gif" width="414" height="81" />
        </div>

        {allDzemoApiArticles.length > 0 && (
          <WeUse data={allDzemoApiArticles[0]} />
        )}

        <section
          style={{
            minHeight: "250px",
          }}
        >
          <div className="team-members-container">
            {profiles.map((profile, index) => {
              return (
                <TeamMember
                  key={profile.node[lang].id}
                  profile={profile.node[lang]}
                  storyIndex={index}
                />
              )
            })}
          </div>
        </section>
        <section
          style={{
            display: "flex",
            flexDirection: "column",
            minHeight: "250px",
          }}
        >
          {localizedZigzags.length > 0 &&
            localizedZigzags.map((zigzag, index) => {
              return (
                <ZigZag
                  key={zigzag.id}
                  data={{
                    ...zigzag,
                    image: zigzag.image[0],
                    sideOfImage: zigzagImageSides[index],
                    overrideResolution: { width: 684, height: 427 },
                  }}
                />
              )
            })}
        </section>

        <div className="wave-wrapper">
          <Wave />
        </div>
        <section
          style={{
            background: "rgba(241, 241, 241, 1)",
            minHeight: "717px",
          }}
        >
          <TestimonialSlider>
            {testimonials.map(testimonial => {
              const { id } = testimonial.node[lang]
              return <Testimonial key={id} data={testimonial.node[lang]} />
            })}
          </TestimonialSlider>
        </section>

        <section
          style={{
            display: "flex",
            justifyContent: "center",
            minHeight: "250px",
            background: "black",
          }}
        >
          <Contact
            mode="black"
            message={{
              message: "Your message has been sent!",
              type: MESSAGE_TYPES.success,
            }}
          />
        </section>
      </div>
    </Layout>
  )
}

export default Company

export const query = graphql`
  query companyPageQuery($pageId: Int!) {
    allDzemoApiTops(filter: { en: { page: { id: { eq: $pageId } } } }) {
      nodes {
        id
        en {
          name
          route
          image {
            alternativeText
            ext
            hash
            height
            id
            name
            size
            url
            width
            formats {
              normal {
                height
                width
                name
                size
                url
              }
              normal_sm {
                height
                width
                name
                size
                url
              }
            }
            caption
          }
          body
        }
        de {
          name
          route
          image {
            alternativeText
            ext
            hash
            height
            id
            name
            size
            url
            width
            formats {
              normal {
                height
                width
                name
                size
                url
              }
              normal_sm {
                height
                width
                name
                size
                url
              }
            }
            caption
          }
          body
        }
      }
    }

    allDzemoApiDoubles {
      nodes {
        en {
          title1
          title2
          text1
          text2
          id
          image {
            formats {
              double {
                height
                url
                width
                hash
              }
              double_sm {
                hash
                height
                width
                url
              }
            }
            alternativeText
          }
        }
        de {
          title1
          title2
          text1
          text2
          id
          image {
            formats {
              double {
                height
                url
                width
                hash
              }
              double_sm {
                hash
                height
                width
                url
              }
            }
            alternativeText
          }
        }
      }
    }

    allDzemoApiProfiles(sort: { fields: en___user___weight, order: ASC }) {
      nodes {
        en {
          image {
            formats {
              team {
                hash
                height
                url
                width
              }
              team_mini {
                url
                width
                height
                hash
              }
              team_small {
                hash
                height
                url
                width
              }
            }
            alternativeText
          }
          position {
            name
            name_de
          }
          id
          user {
            username
            username_de
          }
          linkedin
        }
        de {
          image {
            formats {
              team {
                hash
                height
                url
                width
              }
              team_mini {
                url
                width
                height
                hash
              }
              team_small {
                hash
                height
                url
                width
              }
            }
            alternativeText
          }
          position {
            name
            name_de
          }
          id
          user {
            username
            username_de
          }
          linkedin
        }
      }
    }

    allDzemoApiArticles(
      filter: { en: { page: { id: { eq: $pageId } } } }
      limit: 1
    ) {
      nodes {
        en {
          id
          body
          title
        }
        de {
          id
          body
          title
        }
      }
    }

    allDzemoApiZigzags(
      filter: { en: { page: { id: { eq: $pageId } } } }
      sort: { fields: en___weight, order: ASC }
    ) {
      nodes {
        en {
          id
          body
          name
          image {
            formats {
              normal {
                url
                width
                size
                name
                height
              }
              normal_sm {
                url
                width
                size
                name
                height
              }
            }
            alternativeText
          }
          route
          id
        }
        de {
          id
          body
          name
          image {
            formats {
              normal {
                url
                width
                size
                name
                height
              }
              normal_sm {
                url
                width
                size
                name
                height
              }
            }
            alternativeText
          }
          route
          id
        }
      }
    }

    allDzemoApiTestimonials(
      filter: { en: { featured: { ne: null } } }
      sort: { fields: en___created_at, order: DESC }
    ) {
      nodes {
        en {
          id
          body
          name
          image {
            alternativeText
            caption
            size
            formats {
              thumbnail {
                url
                width
                height
              }
            }
            hash
          }
        }
        de {
          id
          body
          name
          image {
            alternativeText
            caption
            size
            formats {
              thumbnail {
                url
                width
                height
              }
            }
            hash
          }
        }
      }
    }
  }
`
